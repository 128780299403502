<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Stati ordine">
        <div class="table-layout-container">

          <div class="row">
            <div class="col-12 col-md-4 mb-3">
              <router-link :to="{path:'/stati/aggiungi'}">
                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-plus"></i> Aggiungi stato
                </span>
                </button>
              </router-link>
            </div>
          </div>

          <div class="row" style="min-height: 150px">

            <div class="col-12" v-loading="loading">
              <el-table :data="tableData" border style="width: 100%; margin-bottom: 30px;"
                        :expand-row-keys="currentState.expandedRows" row-key="id" empty-text="-"
                        @expand-change="expandedRow" v-if="!loading">

                <el-table-column type="expand" :width="50">
                  <template #default="props">
                    <div v-if="props.row">

                      <p v-if="props.row.child_statuses.length < 1">Questo stato non ha altri stati al suo interno.</p>

                      <table class="table table-striped" v-if="props.row.child_statuses.length > 0">
                        <thead></thead>
                        <tbody>
                        <tr v-for="(childStatus, index) in props.row.child_statuses">
                          <td>{{ childStatus.internal_name }}</td>
                          <td>{{ childStatus.name }}</td>
                          <td style="text-align: center" class="table-actions">

                            <router-link :to="{path:'/stati/lista/modifica/' + childStatus.id}"
                                         v-if="canEdit(childStatus)">
                              <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link"><i
                                class="fa fa-edit"></i></a>
                            </router-link>

                            <a data-tooltip="'Sposta sù'" class="btn-simple btn-link"
                               @click="moveUp(childStatus, props.row.child_statuses)"
                               :disabled="!canUp(childStatus, props.row.child_statuses)"
                               v-bind:class="{ disabled: !canUp(childStatus, props.row.child_statuses), 'btn-success': canUp(childStatus, props.row.child_statuses)}"><i
                              class="fa fa-arrow-up"></i></a>
                            <a data-tooltip="'Sposta giù'" class="btn-simple btn-link"
                               @click="moveDown(childStatus, props.row.child_statuses)"
                               :disabled="!canDown(childStatus, props.row.child_statuses)"
                               v-bind:class="{ disabled: !canDown(childStatus, props.row.child_statuses), 'btn-success': canDown(childStatus, props.row.child_statuses)}"><i
                              class="fa fa-arrow-down"></i></a>

                            <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                               @click="handleDelete(childStatus)" v-if="canDelete(childStatus)"><i
                              class="fa fa-times"></i></a>

                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column type="default" prop="id" label="ID" :width="70"></el-table-column>

                <el-table-column type="default" prop="internal_name" label="Nome interno"></el-table-column>

                <el-table-column type="default" prop="name" label="Nome al pubblico"></el-table-column>


                <el-table-column :width="calculateTableWidth(componentSettings.table.percentWidth.action)"
                                 label="Azioni">
                  <template #default="props">
                    <div style="text-align: center" class="table-actions" v-if="props.row">

                      <router-link :to="{path:'/stati/aggiungi/' + props.row.id}">
                        <a data-tooltip="'Aggiungi stato qui'" class="btn-success-green btn-simple btn-link"><i
                          class="fa fa-plus"></i></a>
                      </router-link>

                      <router-link :to="{path:'/stati/lista/modifica/' + props.row.id}" v-if="canEdit(props.row)">
                        <a data-tooltip="'Modifica'" class="btn-warning btn-simple btn-link"><i
                          class="fa fa-edit"></i></a>
                      </router-link>

                      <a data-tooltip="'Sposta sù'" class="btn-simple btn-link" @click="moveUp(props.row)"
                         :disabled="!canUp(props.row)"
                         v-bind:class="{ disabled: !canUp(props.row), 'btn-success': canUp(props.row)}"><i
                        class="fa fa-arrow-up"></i></a>
                      <a data-tooltip="'Sposta giù'" class="btn-simple btn-link" @click="moveDown(props.row)"
                         :disabled="!canDown(props.row)"
                         v-bind:class="{ disabled: !canDown(props.row), 'btn-success': canDown(props.row)}"><i
                        class="fa fa-arrow-down"></i></a>

                      <a data-tooltip="'Elimina'" class="btn-danger btn-simple btn-link"
                         @click="handleDelete(props.row)" v-if="canDelete(props.row)"><i class="fa fa-times"></i></a>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>


        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'

export default {
  components: {
    LPagination,
    Breadcrumb,
    BreadcrumbItem,
  },
  computed: {},
  data() {
    return {
      componentSettings: {
        enableMultipleSelect: false,
        myComponentName: 'StatusesList',
        minimumPermissionLevel: 100,
        table: {
          percentWidth: {
            expand: 4,
            internal_name: 38,
            name: 40,
            action: 18
          }
        }
      },
      loading: false,
      tableData: [],
      canShowView: false,
      currentState: {
        expandedRows: []
      }
    }
  },
  methods: {
    getSaveStateConfig() {
      return {
        'cacheKey': this.componentSettings.myComponentName,
        'saveProperties': ['currentState']
      }
    },
    expandedRow(row) {
      var index = this.currentState.expandedRows.indexOf(row.id)
      if (index === -1) this.currentState.expandedRows.push(row.id)
      else this.currentState.expandedRows.splice(index, 1)
    },
    changeIndex(elementToChange, direction) {
      this.loading = true
      var payload = {status: elementToChange, direction: direction}
      this.$store.dispatch('orders/statuses/indexEdit', payload).then(() => {
        this.loading = false
        this.updateData()
      }).catch(e => {
        this.loading = false
        this.showResponseErrorMessage(e)
      })
    },
    moveDown(elementToMove, parentChilds = null) {
      if (!this.canDown(elementToMove, parentChilds)) {
        return
      }
      this.changeIndex(elementToMove, 'up')
    },
    moveUp(elementToMove, parentChilds = null) {
      if (!this.canUp(elementToMove, parentChilds)) {
        return
      }
      this.changeIndex(elementToMove, 'down')
    },
    handleDelete(elementToDelete) {
      this.askDeleteMessage('questo elemento').then((result) => {
        if (!result.value) return
          this.loading = true
          this.$store.dispatch('orders/statuses/delete', elementToDelete).then(() => {
            this.loading = false
            var index = this.currentState.expandedRows.indexOf(elementToDelete.id)
            if (index !== -1) this.currentState.expandedRows.splice(index, 1)

            this.$message.success("Stato eliminato.")
            this.updateData()
          }).catch(e => {
            this.$message.error("Errore durante l'eliminazione di questa stato.")
          })
        }).finally(() => {
        this.loading = false;
      })
    },
    updateData(params) {
      this.loading = true
      if (!params) params = {}
      this.$store.dispatch('orders/statuses/list', params).then((response) => {
        this.loading = false
        this.tableData = response.data.order_statuses
      }).catch(e => {
        this.loading = false
        this.showLoadingError(e)
      })
    },
    canEdit(pElement) {
      return this.$store.getters['orders/statuses/canEdit'](pElement)
    },
    canDelete(pElement) {
      return this.$store.getters['orders/statuses/canDelete'](pElement)
    },
    canUp(pElement, parentChilds = null) {
      if (pElement.parent_status_id === 0 && pElement.index > this.tableData[0].index) return true
      if (pElement.parent_status_id !== 0 && parentChilds !== null) {
        if (pElement.index > parentChilds[0].index) return true
      }
      return false
    },
    canDown(pElement, parentChilds = null) {
      if (pElement.parent_status_id === 0 && pElement.index >= this.tableData[this.tableData.length - 1].index) return false
      if (pElement.parent_status_id !== 0 && parentChilds !== null) {
        if (pElement.index >= parentChilds[parentChilds.length - 1].index) return false
      }
      return true
    }
  },
  created: function () {
    if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
    this.canShowView = true
    this.updateData()
  },
  mounted: function () {
  },
  destroyed: function () {
    this.$off()
  }
}
</script>
<style>
.el-table__expand-column {
  padding-left: 6px !important;
}
</style>
