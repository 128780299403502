<template>
    <div class="nav-item"
               :class="{active : isActive}" tag="li">
        <div>
            <a v-if="isMenu"
               class="nav-link sidebar-menu-item"
               :aria-expanded="!collapsed"
               data-toggle="collapse"
               @click="collapseMenu">
                <i :class="link.icon"></i>
                <p>{{ link.name }}<b class="caret"></b>
                </p>

            </a>
            <router-link v-else
               class="nav-link sidebar-menu-item" :to="link.path">
                <i :class="link.icon"></i>
                <p>{{ link.name }}</p>
            </router-link>
            <div v-if="this.isMenu && this.link.subLinks.length > 0" class="ml-4 border-left">
                <el-collapse-transition>
                    <ul class="nav" v-show="!collapsed">
                        <li class="nav-item" v-for="(child) in link.subLinks">
                            <router-link :to="{path: child.path}" class="nav-link sidebar-menu-item">
                                <p>{{ child.name }}</p>
                            </router-link>
                        </li>
                    </ul>
                </el-collapse-transition>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'sidebar-item',
    components: {},
    props: {
        link: null
    },
    data() {
        return {
            children: [],
            collapsed: true
        }
    },
    computed: {
        isMenu() {
            return this.link.subLinks && this.link.subLinks.length > 0
        },
        isActive() {
            if (this.$route && this.$route.path) {

                if (this.link.subLinks && this.link.subLinks.length > 0) {

                    let matchingRoute = this.link.subLinks.find((c) => {
                        return this.$route.path.startsWith(c.path)
                    })
                    if (matchingRoute !== undefined) {
                        return true
                    }
                }
                if (this.$route.path.startsWith(this.link.path)) {
                    return true
                }
            }
            return false
        }
    },
    methods: {
        collapseMenu() {
            this.collapsed = !this.collapsed
        },
        collapseSubMenu(link) {
            link.collapsed = !link.collapsed
        }
    },
    mounted() {
        if (this.isActive && this.isMenu) {
            this.collapsed = false
        }
    },
    destroyed() {
    }
}
</script>
<style>
.sidebar-menu-item {
    cursor: pointer;
}
</style>
