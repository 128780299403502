<template>
    <div class="sidebar" style="background-image: url('/static/img/sidebar-6.jpg');">

        <div class="sidebar-wrapper" ref="sidebarScrollArea" style="background-color: #0000008f">
            <div class="">
                <a class="" href="">
                    <img src="/static/img/logo_white.png" alt="logo" style="max-width: 100%; padding: 10px 15px 0 15px">
                </a>
            </div>
            <user-menu/>
            <div class="w-100 px-4">
                <label
                    style="width: 100%; color: #fff !important; text-transform: uppercase; font-size: 12px; font-weight: bold">Ricerca
                    intelligente</label>
                <el-input v-model.trim="currentSearchOrdine" v-on:keyup.enter="searchOrder"
                          addon-right-icon="fa fa-search"
                          type="text" placeholder="es. 3120000x" class="mb-1"/>
                <div style="clear: both; line-height: 1;"><small style="color: rgba(255, 255, 255, 0.7);">Puoi
                    cercare per:
                    ordine, mail, nome, codice fiscale o telefono.</small></div>
            </div>
            <hr class="bg-light-gray"/>
            <ul class="nav px-2">
                <sidebar-item v-for="(link, index) in links" :link="link" :key="index" />
            </ul>
        </div>
    </div>
</template>
<script>
import UserMenu from "@/pages/Dashboard/Layout/Extra/UserMenu.vue";
import SidebarItem from "@/components/SidebarPlugin/SidebarItem.vue";

export default {
    name: 'sidebar',
    components: {SidebarItem, UserMenu},
    props: {
        title: {
            type: String,
            default: 'Console'
        },
        autoClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentSearchOrdine: null,
            links: []
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        }
    },
    methods: {
        minimumPermission: function (pMinimumPermission) {
            if (this.checkIfPermissionLevelIsAtLeast(pMinimumPermission, false)) return true
            return false
        },
        searchOrder() {
            let currentSearch = this.currentSearchOrdine
            this.loading = true
            setTimeout(() => (this.loading = false), 600)
            if (currentSearch.includes('@')) {
                this.$router.push('/ordini/lista/email/' + currentSearch)
            } else if (currentSearch !== '' && currentSearch.match(/^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}/i)) {
                this.$router.push('/ordini/lista/codicefiscale/' + currentSearch)
            } else if (currentSearch !== '' && currentSearch.match(/(DI?ME?)[\_\s]+([A-Z0-9]+)[\_\s]?([A-Z0-9]*)([A-Z0-9\_]*)/)) {
                const matches = currentSearch.match(/(DI?ME?)[\_\s]+([A-Z0-9]+)[\_\s]?([A-Z0-9]*)([A-Z0-9\_]*)/);
                console.log(matches);
                //const codiceOLO = matches[0];
                const codiceCliente = matches[2];
                const codiceOrdine = matches[3];
                if (codiceOrdine) {
                    this.$router.push('/ordini/lista/modifica/' + codiceOrdine)
                } else {
                    this.$router.push('/ordini/lista/codicecliente/' + codiceCliente)
                }
            } else if (currentSearch !== '' && (currentSearch.replace(/[^0-9]/g, '').length >= 1 && currentSearch.replace(/[^0-9]/g, '').length <= 5)) {
                currentSearch = currentSearch.replace(/[^0-9]/g, '')
                this.$router.push('/ordini/lista/codicecliente/' + currentSearch)
            } else if (currentSearch !== '' && currentSearch.replace(/[^0-9]/g, '').length === 7) {
                currentSearch = currentSearch.replace(/[^0-9]/g, '')
                this.$router.push('/ordini/lista/modifica/' + currentSearch)
            } else if (currentSearch !== '' && currentSearch.replace(/[^0-9]/g, '').length > 6) {
                currentSearch = currentSearch.replace(/[^0-9]/g, '')
                this.$router.push('/ordini/lista/telefono/' + currentSearch)
            } else if (currentSearch !== '' && currentSearch.length >= 4) {
                this.$router.push('/ordini/lista/nome/' + currentSearch)
            } else {
                this.showGenericError('Errore!', 'Puoi cercare solo per: ID Ordine, eMail, numero di telefono e Nome/Cognome cliente, almeno 4 caratteri.<br /><br />Puoi eseguire tutte le altre tipologie di ricerca dalla sezione Ordini - Visualizzazione.')
            }
            this.currentSearchOrdine = ''
        }
    },
    mounted() {
        this.links = this.$sidebar.sidebarLinks
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false
        }
    }
}

</script>
<style>
@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}
</style>
