<template>
  <auth-layout pageClass="login-page" v-loading="loading">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
        <el-card v-if="!verifyAuthentication">
          <div slot="header">
            <h3 class="card-title text-center">Accedi</h3>
          </div>
          <login-form @logged="loggedIn"/>
        </el-card>

        <el-card v-if="verifyAuthentication">
          <div slot="header">
            <h3 class="card-title text-center">Verifica autenticazione</h3>
          </div>
          <two-factor-auth-form :user-id="authData.user_id" @authenticated="authenticated"/>
        </el-card>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {Checkbox as LCheckbox, FadeRenderTransition} from '@/components'
import AuthLayout from '../Pages/AuthLayout.vue'
import LoginForm from "@/pages/Dashboard/Login/Components/LoginForm";
import TwoFactorAuthForm from "@/pages/Dashboard/Login/Components/TwoFactorAuthCodeForm";

export default {
  name: 'LoginPage',
  components: {
    TwoFactorAuthForm,
    LoginForm,
    LCheckbox,
    FadeRenderTransition,
    AuthLayout
  },
  data() {
    return {
      submitted: false,
      model: {
        loginResult: true,
        email: '',
        password: ''
      },
      verifyAuthentication: false,
      loading: false,
      qrUrl: null,
      otp: '',
      user: null,
      authData: null,
      twoFactorAuth: null
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    loggedIn(authData) {
      this.authData = authData;

      if (this.authData.two_fa_session) {
        this.loadUser(this.authData.user_id).then(() => {
          this.loginUser(this.$config.homepage);
        })
        return
      }

      if (!this.authData.authenticator_enabled) {
        this.loadUser(this.authData.user_id).then(() => {
          this.loginUser(this.$config.profilePage + this.authData.user_id)
        })
        return;
      }

      this.verifyAuthentication = true;

    },
    loadUser(userId) {

      let $this = this

      return new Promise(function (resolve, reject) {

        $this.axios.post($this.$config.endpoints.users.base + userId, {
          api_key: $this.$config.apiKey,
          session_token: $this.authData.session_token,
          user_id: userId
        }).then(response => {
          $this.user = response.data.data;
          return resolve($this.user)
        }).catch(e => {
          return reject(e)
        })
      })
    },
    authenticated(twoFactorAuthData) {
      console.log(twoFactorAuthData)
      this.authData.two_fa_session = twoFactorAuthData
      this.loadUser(this.authData.user_id).then(() => {
        this.loginUser(this.$config.homepage);
      })
      return
    },
    loginUser(redirect) {
      this.loading = true;
      this.$store.dispatch('login', this.authData)
      this.$store.commit('saveUser', this.user)
      this.$store.commit('saveTwoFactorAuth', this.authData.two_fa_session)
      this.$store.dispatch('loadInitialData').then(() => {
        this.$router.push(redirect)
      }).catch(() => {
        this.showErrorMessage("Qualcosa è andato storto. Riprovare.")
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created: function () {
    // Pulizia del local storage al logout/login
    let keys = Object.keys(localStorage)
    let i = keys.length
    while (i--) {
      let keyName = keys[i]
      if (keyName !== 'vuex') {
        localStorage.removeItem(keyName)
      }
    }
    if (this.$store.getters.sessionToken !== null) {
      if (this.$store.getters.requestedPath) {
        this.$router.push(this.$store.getters.requestedPath)
        this.$store.commit('setRequestedPath', null)
        return
      }
      this.$router.push(this.$config.homepage)
    }
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
