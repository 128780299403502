<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h4>Autenticazione a due fattori</h4>
                <el-alert
                    title="Attiva subito l'autenticazinoe a due fattori."
                    type="warning"
                    description="Scarica sul tuo smartphone l'app Google Authenticator, e segui le indicazioni mostrate qui in basso."
                    show-icon :closable="false"/>
            </div>
        </div>

        <div class="row py-4">
            <div class="col-12">
                <el-steps :active="activeStep" align-center>
                    <el-step title="Scarica l'app"/>
                    <el-step title="Scannerizza qr code"/>
                    <el-step title="Completa attivazione"/>
                </el-steps>
            </div>
        </div>

        <div class="row" v-if="activeStep === 0">
            <div class="col-12 text-center">
                <div class="flex-column border-gray border py-4">
                    <h5>Scarica l'app Google Authenticator sul tuo smartphone</h5>
                    <div class="d-flex justify-content-center">
                        <el-link
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=it&gl=US"
                            icon="el-icon-download" class="mr-4">Android
                        </el-link>
                        <el-link href="https://apps.apple.com/it/app/google-authenticator/id388497605"
                                 icon="el-icon-download">iOS
                        </el-link>
                    </div>
                    <el-button type="primary" @click="nextStep" class="mt-4">Fatto</el-button>
                </div>
            </div>
        </div>

        <div class="row" v-if="activeStep === 1">
            <div class="col-12">
                <div class="border-gray border py-4 text-center">
                    <h5>Apri l'app e scannerizza il QR Code</h5>

                    <div class="d-flex justify-content-center">
                        <el-skeleton v-if="!qrUrl">
                            <template slot="template">
                                <el-skeleton-item variant="image" style="width: 240px; height: 240px;"/>
                            </template>
                        </el-skeleton>
                        <el-image v-if="qrUrl" :src="qrUrl" style="width: 240px; height: 240px;"/>
                    </div>

                    <div class="text-center mt-4">
                        <p class="mb-0">Oppure inserisci questo codice manualmente</p>
                        <code>{{ secret }}</code>
                    </div>

                    <el-button type="primary" @click="nextStep" class="mt-4">Fatto</el-button>
                </div>
            </div>
        </div>

        <div class="row" v-if="activeStep === 2">
            <div class="col-12">
                <div class="border-gray border py-4 px-2 text-center">
                    <h5>Verifica l'attivazione</h5>
                    <p>Inserisci qui il codice che vedi nell'app</p>

                    <el-form>
                        <el-form-item prop="otpToVerify">
                            <el-input v-model="otpToVerify" placeholder="CODICE OTP"/>
                        </el-form-item>
                    </el-form>

                    <el-button type="success" @click="verifyOtp" class="mt-2">Verifica</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "TwoFactorAuthenticationSection",
    created() {
        this.loadTwoFactorAuthenticationQrCode()
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            qrUrl: null,
            secret: null,
            loading: false,
            activeStep: 0,
            otpToVerify: null
        }
    },
    methods: {
        loadTwoFactorAuthenticationQrCode() {
            this.axios.post(this.$config.endpoints.auth.two_fa_get_code,
                this.$store.getters.apiCommon).then(response => {
                this.qrUrl = response.data.data.qr_code_url;
                this.secret = response.data.data.secret;
            }).catch(e => {
                console.log(e)
            })
        },
        nextStep() {
            this.activeStep = this.activeStep + 1;
        },
        verifyOtp() {

            let data = this.$store.getters.apiCommon;
            data.code = this.otpToVerify
            data.secret = this.secret

            this.axios.post(this.$config.endpoints.auth.two_fa_enable_code, data).then(response => {
                this.showSuccessMessage("Autenticazione a due fattori abilitata con successo.")
                this.$emit("input", response.data.data)
            }).catch(e => {
                this.showResponseErrorMessage(e)
                console.log(e)
            })
        }
    }
}
</script>

<style scoped>

</style>
