<template>
  <el-card v-loading="loading">
    <div slot="header">
      <h4 class="title mt-0 mb-0">Creazione utente</h4>
    </div>
    <div>
      <el-form v-model="user">
        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item prop="name" label="Nome">
              <el-input v-model="user.name" />
            </el-form-item>
          </div>
          <div class="col-md-6 col-12">
            <el-form-item prop="email" label="Email">
              <el-input v-model="user.email" />
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item label="Ruolo" prop="role">
              <el-select v-model="user.role" placeholder="...">
                <el-option
                  v-for="(role, key) in roles"
                  :key="key"
                  :label="key"
                  :value="role">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6 col-12">
            <el-form-item label="Accesso al gestionale">
              <el-switch v-model="user.admin_access" type="primary" on-text="SÌ" off-text="NO"></el-switch>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item prop="password" label="Password">
              <el-input v-model="user.password" />
            </el-form-item>
          </div>

          <div class="col-md-6 col-12">
            <el-form-item prop="password" label="Conferma password">
              <el-input v-model="user.confirmPassword" />
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <el-button @click="validateForm" :disabled="loading" type="primary">Crea utente</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </el-card>
</template>
<style>
</style>
<script>
  import {Switch as LSwitch} from 'src/components/index'

  export default {
    components: {
      LSwitch
    },
    data () {
      return {
        user: {
          name: '',
          email: '',
          password: '',
          admin_access: true,
          role: 100,
          company_id: 1,
          working_hours: 8,
          included_attendance_export: true
        },
        roles: this.$store.getters.commonElements.roles,
        companies: this.$store.getters.commonElements.companies,
        employment_companies: [],
        confirmPassword: '',
        modelValidations: {
          name: {
            required: true,
            min: 5
          },
          email: {
            required: true,
            email: true
          },
          password: {
            required: true,
            min: 8,
            max: 25
          },
          confirmPassword: {
            required: true,
            confirmed: 'password',
            min: 8,
            max: 25
          }
        },
        loading: false
      }
    },
    methods: {
      validateForm () {
        this.addProfile()
      },
      addProfile () {
        this.loading = true
        this.$store.dispatch('users/add', this.user).then((response) => {
          this.loading = false
          this.$router.push('/utenti/lista/modifica/' + response.data.user.id)
        }).catch(e => {
          this.loading = false
          console.log('error', e)
          this.showResponseErrorMessage(e)
        })
      },
      loadEmploymentCompanies () {
        this.loading = true
        this.$store.dispatch('users/getEmploymentCompanies').then((response) => {
          this.loading = false
          this.employment_companies = response
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'il caricamento delle aziende')
        })
      }
    },
    created: function () {
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
</style>
