<template>
  <div>
    <form>
      <div class="row">
        <div class="col-12">

          <div class="row">

            <div class="col-6">
              <el-input type="text"
                        label="Nome interno"
                        placeholder="Nome interno"
                        v-model="status.internal_name"
                        name="internal_name">
              </el-input>
            </div>

            <div class="col-6">
              <el-input type="text"
                        label="Nome al pubblico"
                        placeholder="Nome al pubblico"
                        v-model="status.name"
                        name="name">
              </el-input>
            </div>

          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-4 col-12">
          <label>Mostra conteggio ordini</label>
          <br/>
          <l-switch v-model="status.countable" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>

        <div class="col-md-4 col-12">
          <label>In evidenza</label>
          <br/>
          <l-switch v-model="status.important" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>

        <div class="col-md-4 col-12">
          <label>Nascondi</label>
          <br/>
          <l-switch v-model="status.hidden" type="primary" on-text="SÌ" off-text="NO"></l-switch>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Punto nel flusso</label>
          <div class="row">
            <el-select class="select-default col-12" v-model="status.flow_order" filterable placeholder="..."
                       size="large">
              <el-option
                v-for="item in flows"
                class="select-default"
                :key="item.code"
                :label="item.code + ' > ' + item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <label>Stato madre</label>
          <div class="row">
            <el-select class="select-default col-12" v-model="status.parent_status_id" filterable placeholder="..."
                       size="large">

              <el-option
                v-for="item in newParentStatuses"
                v-if="(item.id != status.id && !hasChildrenStatuses) || item.id === 0"
                class="select-default"
                :key="item.id"
                :label="item.internal_name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-3">
          <label>Descrizione mostrata/letta al cliente</label>
          <br/>
          <el-input type="textarea" v-model="status.description"></el-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <el-button class="mr-2" type="danger" icon="delete" @click.prevent="deleteStatus">
            Elimina
          </el-button>
          <el-button type="primary" icon="check" @click.prevent="validateForm">Salva</el-button>
        </div>
      </div>
    </form>
  </div>
</template>
<style>
</style>
<script>
import {Switch as LSwitch} from 'src/components/index'

export default {
  components: {
    LSwitch
  },
  props: {
    status: {},
    parentStatuses: {},
    flows: {}
  },
  data() {
    return {
      modelValidations: {
        name: {
          required: true
        },
        internal_name: {
          required: true
        }
      },
      hasChildrenStatuses: false,
      newParentStatuses: []
    }
  },
  watch: {
    parentStatuses: function () {
      this.checkParentStatuses()
    }
  },
  computed: {},
  methods: {
    checkParentStatuses() {
      if (this.hasChildrenStatuses) return
      if (this.parentStatuses.length <= 0) return
      if (this.status.id) {
        for (var item of this.parentStatuses) {
          if (item.id !== this.status.id) continue
          if (item.child_statuses.length > 0) {
            this.hasChildrenStatuses = true
            break
          }
        }
      }
      var parent = JSON.parse(JSON.stringify(this.parentStatuses))
      var zeroStatus = {id: 0, name: '*** Nessuno: stato madre ***', child_statuses: []}
      if (!Array.isArray(parent)) {
        this.newParentStatuses = [zeroStatus]
      } else {
        parent.unshift(zeroStatus)
        this.newParentStatuses = parent
      }
    },
    deleteStatus() {
      this.$emit('delete', this.status)
    },
    validateForm() {
      this.$emit('update', this.status)
    }
  },
  created: function () {
    this.checkParentStatuses()
  },
  mounted: function () {
  },
  destroyed: function () {
  }
}

</script>
<style></style>
