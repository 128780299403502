<template>
  <div v-loading="loading">
    <el-form @submit.prevent.native="verifyCode" label-position="top">
      <el-form-item label="Codice di sicurezza">
        <el-input v-model="code" name="otp"/>
      </el-form-item>
      <div class="text-center mt-2">
        <el-button type="primary" native-type="submit" round v-bind:disabled="submitted"
                @click="submit">VERIFICA
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "TwoFactorAuthForm",
  data() {
    return {
      code: null,
      loading: false,
      submitted: false
    }
  },
  props: {
    userId: {
      required: true
    }
  },
  methods: {
    submit() {
      this.submitted = true
      this.loading = true

      let data = {
        api_key: this.$config.apiKey,
        user_id: this.userId,
        code: this.code
      }

      this.axios.post(this.$config.endpoints.auth.two_fa_verify_code, data).then(response => {
        this.$emit('authenticated', response.data.data)
      }).catch(e => {
        this.showResponseErrorMessage(e)
      }).finally(() => {
        this.loading = false
        this.submitted = false
      })
    }
  }
}
</script>

<style scoped>

</style>
