<template>
    <div id="contract-page" class="wrapper">
        <div class="content" v-loading="loading">

            <div v-if="!dataCheck" class="container py-5">
                <div class="row">
                    <div class="col-12">
                        <el-card class="bg-danger">
                            <div slot="header" class="clearfix">
                                <h3 class="m-0 text-center text-white">Dati mancanti per la generazione del
                                    contratto</h3>
                            </div>
                            <div>
                                <p class="m-0 text-white text-center">{{ dataCheckError }}</p>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>

            <div v-if="dataCheck" class="container pb-5" ref="contract">

                <div class="row mt-5" v-if="!loading">
                    <div class="col-12">
                        <img :src="this.baseUrl + '/static/img/logo.png'" class="mx-auto d-block" alt="Responsive image"
                             style="max-width: 300px">
                    </div>

                    <div class="col-12 text-center">
                        <h4 class="mb-3">PROPOSTA DI ABBONAMENTO SERVIZIO {{ order.type_details.name }}</h4>
                        <h6>CODICE ABBONAMENTO #{{ order.id }}</h6>
                    </div>
                </div>

                <div v-if="order.id && !contract">
                    <div class="contract-section">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="contract-header">DATI INTESTATARIO CONTRATTO</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <p>Nome/Ragione Sociale: <span class="underline">{{
                                        this.order.customer.first_name
                                    }}</span></p>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <p>Cognome/Rappresentante legale: <span
                                    class="underline">{{ this.order.customer.last_name }}</span></p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <p>Luogo di nascita: <span class="underline">{{
                                        this.order.customer.luogo_nascita
                                    }}</span></p>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>Data di nascita (gg/mm/aaaa): <span
                                    class="underline">{{
                                        dateToItalian(this.order.customer.data_nascita, false, 'DD/MM/YYYY')
                                    }}</span>
                                </p>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>CF: <span class="underline">{{ this.order.customer.codice_fiscale }}</span></p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <p>PIVA: <span class="underline">{{ this.order.customer.partita_iva }}</span></p>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <p>Codice Univoco: <span class="underline"></span></p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <p>Indirizzo: <span
                                    class="underline">{{ this.order.customer.indirizzo_residenza }}, {{
                                        this.order.customer.numero_civico
                                    }}</span>
                                </p>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <p>Località: <span class="underline">{{ this.order.customer.comune }}</span></p>
                            </div>
                            <div class="col-sm-6 col-md-2">
                                <p>Provincia: <span class="underline">{{ this.order.customer.provincia }}</span></p>
                            </div>
                            <div class="col-sm-6 col-md-2">
                                <p>CAP: <span class="underline">{{ this.order.customer.cap }}</span></p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-3">
                                <p>Telefono fisso*: <span class="underline"></span></p>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <p>Cellulare*: <span class="underline">{{
                                        this.order.customer.mobile_phone_number
                                    }}</span></p>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <p>e-mail*: <span class="underline">{{ this.order.customer.email }}</span></p>
                            </div>
                        </div>
                    </div>

                    <div class="contract-section">
                        <div class="row mt-4">
                            <div class="col-12">
                                <h5 class="contract-header">CONTRATTO</h5>
                            </div>
                        </div>

                        <div class="row justify-content-center" v-loading="this.contractPreviewLoading">
                            <div class="col-12">
                                <p>Visualizza il contratto <span class="btn-link" @click="this.showContract">cliccando qui</span>
                                </p>
                            </div>
                            <div class="col-12">
                                <p>Dichiaro di aver preso visione del contratto e di averne accettato le condizioni:
                                    <input
                                        type="checkbox" v-model="contractViewed">
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="contract-section">
                        <div class="row mt-5">
                            <div class="col-12">
                                <h5 class="contract-header">FIRMA DEL CONTRATTO</h5>
                            </div>
                            <div class="col-12" v-if="signatureMethod && contractViewed">
                                <a class="a-link btn-link" @click="removeSelectedSignMethod">Cambia metodo di firma <i
                                    class="edit ml-1"></i></a>
                            </div>
                        </div>

                        <div class="col-12" v-if="!contractViewed">
                            <el-alert
                                title="Per poter procedere devi prima aver visionato ed accettato le condizioni del contratto spuntando la casella più sopra."
                                type="error" show-icon :closable="false" class="mb-2">
                            </el-alert>
                        </div>

                        <!-- SE HA MESSO LA SPUNTA MA NON HA SELEZIONATO UN METODO DI FIRMA -->
                        <div class="row text-center" v-if="contractViewed && !signatureMethod">
                            <div class="col-12">
                                <h4>Come preferisci firmare il contratto?</h4>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <button type="button" class="btn btn-primary btn-block"
                                        @click="setSignatureMethod('sms_otp')">RICHIEDI
                                    CODICE VIA SMS
                                </button>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <button type="button" class="btn btn-primary btn-block"
                                        @click="setSignatureMethod('signature_pad')">
                                    FIRMA DIGITALE
                                </button>
                            </div>
                        </div>

                        <div class="row justify-content-center mt-3" v-if="signatureMethod === 'sms_otp'">
                            <div v-if="contractViewed">
                                <!-- STEP 1 -->
                                <div class="col-12">
                                    <button type="button" class="btn btn-primary btn-block" @click="this.requestOtp"
                                            :disabled="!contractViewed" v-if="!otpRequested"
                                            v-loading="otpRequestLoading">RICHIEDI CODICE
                                        OTP
                                    </button>
                                </div>

                                <!-- STEP 2 -->
                                <div class="col-sm-12 text-center" v-if="otpRequested">
                                    <p>Un sms con il codice è stato inviato al numero
                                        {{ order.customer.mobile_phone_number }}</p>
                                    <el-input
                                        placeholder="XXX-XXX"
                                        v-model="otpCode"
                                        clearable>
                                    </el-input>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3" v-if="signatureMethod === 'signature_pad' && contractViewed">
                            <div class="col-12 sign-pad-container">
                                <VueSignaturePad width="90%" height="270px" ref="signaturePad" :options="padOptions"
                                                 :customStyle="{'background-color': '#ececec', 'margin': '0 auto'}"/>
                            </div>
                        </div>

                        <div class="row mt-5 justify-content-center" v-if="contractViewed && signatureMethod">
                            <div class="col-8">
                                <button type="button" class="btn btn-primary btn-block" @click="this.signContract"
                                        :disabled="!canSign">FIRMA CONTRATTO
                                </button>
                            </div>
                        </div>

                        <div class="row mt-2 justify-content-center" v-if="errorMessage">
                            <div class="col-12">
                                <el-alert
                                    :title="errorMessage"
                                    type="error"
                                    center
                                    show-icon>
                                </el-alert>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="order.id && contract">
                    <div class="row p-5 shadow bg-dark">
                        <div class="col-12 text-center text-success">
                            <h2 class="mb-1">Contratto firmato correttamente!</h2>
                            <h1 class="mt-0"><i style="font-size: 150px" class="fa fa-check d-inline"></i></h1>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 d-flex justify-content-center">
                            <el-button icon="view" @click="openContract">Visualizza contratto</el-button>
                        </div>
                        <div class="col-12 mt-2 d-flex justify-content-center">
                            <el-button type="primary" icon="setting" @click="goToOrder">Vai alla pagina di gesione
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Checkbox from "@/components";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "ContractPage",
    components: {
        Checkbox,
        VueHtml2pdf
    },
    data() {
        return {
            order: {},
            orderHash: null,
            padOptions: {
                backgroundColor: 'transparent',
                penColor: '#000000'
            },
            loading: false,
            contractPreviewLoading: false,
            baseUrl: '',
            contractViewed: false,
            signaturePadEnabled: false,
            otpCode: null,
            otpRequested: false,
            otpRequestLoading: false,
            canSign: false,
            signatureMethod: null,
            signed: false,
            errorMessage: null,
            contract: null,
            dataCheck: true,
            dataCheckError: null
        }
    },
    created() {
        this.orderHash = this.$route.query.hash;
        this.baseUrl = window.location.origin;
        this.retrieveOrder();
    },
    methods: {
        checkOrderData() {

            if (this.order.order_product.operation_type >= 3) {

                if (!this.order.order_product.np) {
                    this.dataCheckError = "Questo ordine richiede una NP ma non risulta creata."
                    this.dataCheck = false;
                    return
                }

                let np = this.order.order_product.np;

                if (!np.original_operator || !np.name || !np.migration_code || np.migration_code.length <= 0) {
                    this.dataCheckError = "Impossibile firmare il contratto in quanto mancano dati relativi alla NP."
                    this.dataCheck = false;
                    return
                }

            }

            if (!this.order.customer.data_nascita) {
                this.dataCheckError = "Manca la data di nascita del cliente."
                this.dataCheck = false;
            }

            if (!this.order.customer.comune_rilascio_documento || !this.order.customer.data_rilascio_documento || !this.order.customer.data_scadenza_documento) {
                this.dataCheckError = "Mancano dati relativi al documento del cliente."
                this.dataCheck = false;
            }
        },
        retrieveOrder() {
            this.loading = true;
            this.$store.dispatch('orders/getByHash', this.orderHash).then((response) => {
                this.order = response.data
                this.loading = false

                this.checkOrderData()
            }).catch(e => {
                this.loading = false
                this.showResponseErrorMessage(e);
            })
        },
        validateCustomerData() {
            if (!this.order.customer.first_name || !this.order.customer.last_name) {

            }
        },
        showContract() {
            this.contractPreviewLoading = true
            let windowReference = window.open();

            this.$store.dispatch('orders/contractPreview', this.order.id).then((response) => {
                let contractPreviewLink = response.data.contract_url;
                windowReference.location = contractPreviewLink;
                this.contractPreviewLoading = false
            }).catch(e => {
                this.contractPreviewLoading = false
                this.showResponseErrorMessage(e)
            });
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        signContract() {

            if (this.signatureMethod === 'signature_pad') {
                let signatureData = this.$refs.signaturePad.toData();
                let signatureImage = this.$refs.signaturePad.saveSignature()

                this.loading = true
                this.$store.dispatch('orders/saveSignature',
                    {
                        orderId: this.order.id,
                        signature_data: signatureData,
                        signature_image: signatureImage.data,
                    }).then((response) => {
                    this.loading = false
                    this.errorMessage = null;
                    this.signed = true
                    this.contractPreviewLoading = false
                    this.contract = response.data.contract
                }).catch((e) => {
                    this.showResponseErrorMessage(e);
                    this.loading = false
                })
            } else {
                this.loading = true

                this.$store.dispatch('orders/signWithOtp',
                    {
                        orderId: this.order.id,
                        otp_code: this.otpCode,
                    })
                    .then((response) => {
                        this.errorMessage = null;
                        this.loading = false
                        this.signed = true
                        this.contractPreviewLoading = false
                        this.contract = response.data.contract
                    })
                    .catch(e => {
                        this.showResponseErrorMessage(e);
                        this.loading = false
                    })
            }
        },
        requestOtp() {

            this.otpRequestLoading = true;

            this.$store.dispatch('orders/sendVerification', {id: this.order.id, use_skebby: true})
                .then((response) => {
                    this.otpRequestLoading = false
                    this.otpRequested = true
                }).catch(e => {
                this.otpRequestLoading = false
            })
        },
        setSignatureMethod(value) {
            this.signatureMethod = value

            if (this.signatureMethod === 'signature_pad' && this.contractViewed) {
                this.canSign = true;
            } else {
                this.canSign = false;
            }
        },
        removeSelectedSignMethod() {
            this.errorMessage = null;
            this.signatureMethod = null;
            this.canSign = false;
        },
        openContract() {
            window.open(this.contract.public_signed_contract_url);
        },
        goToOrder() {
            this.$router.push({path: '/ordini/lista/modifica/' + this.contract.order_id})
        },
    },
    watch: {
        otpCode: function (val) {
            if (val.length >= 6) {
                this.canSign = true;
            } else {
                this.canSign = false;
            }
        }
    }
}
</script>

<style scoped>
.contract-header {
    background-color: #c5c5c5;
    padding: 3px 2px;
}

.underline {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .br-solid {
        border-right: 1px solid #000000;
    }
}

input[type="radio"] {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
}

input[type="checkbox"] {
    width: 25px;
    height: 25px;
    vertical-align: bottom;
}

thead {
    background-color: #2862a3;
    color: #FFFFFF;
}

thead th {
    color: #FFFFFF !important;
    font-weight: bold !important;
}

tr > th {
    background-color: #2862a3;
    color: #FFFFFF !important;
    font-weight: bold !important;
}

</style>






































