<template>
    <el-card v-loading="loading" v-if="user.id">
        <div slot="header">
            <h4 class="title mt-0">Modifica <span v-if="!loading">di <strong>{{ user.name }}</strong></span> <small>(ID:
                {{ user.id }})</small></h4>

            <h5 v-if="!loading">Creato il: {{ dateToItalian(user.created_at, true) }}</h5>
            <p class="text-muted" v-if="!loading">Ultima modifica: {{ dateToItalian(user.updated_at, true) }}</p>

            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <router-link :to="{path:'/utenti/lista'}">
                        <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista utenti
                </span>
                        </button>
                    </router-link>
                </div>
            </div>

        </div>

        <div class="row" v-if="!user.authenticator_enabled">
            <div class="col-12">
                <two-factor-authentication-section :user="user"/>
            </div>
        </div>

        <div v-else>
            <div class="row">
                <div class="col-12">
                    <h4>Informazioni utente</h4>
                </div>
            </div>
            <el-form label-position="top">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <el-form-item label="Nome">
                            <el-input type="text"
                                      label="Nome"
                                      placeholder="Nome"
                                      ref="name"
                                      v-model="user.name">
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-md-6 col-12">
                        <el-form-item label="Email">
                            <el-input type="text"
                                      label="Email"
                                      placeholder="Email"
                                      v-model="user.email"
                                      name="email"
                                      ref="email">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-12">
                        <el-form-item label="Ruolo">
                            <el-select v-model="user.role" placeholder="..."
                                       @change="checkRole()">
                                <el-option
                                    v-for="(role, key) in roles"
                                    class="select-default"
                                    :key="key"
                                    :label="key"
                                    :value="role">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-6">
                        <el-form-item label="Accesso al gestionale">
                            <l-switch v-model="user.admin_access" type="primary" on-text="SÌ"
                                      off-text="NO"></l-switch>
                        </el-form-item>
                    </div>
                </div>

                <div class="row d-none">
                    <div class="col-md-6 col-12">
                        <label>Azienda</label>
                        <div class="row">
                            <el-select class="select-default col-12 mb-3" v-model="user.company_id" filterable
                                       placeholder="..."
                                       size="large">
                                <el-option
                                    v-for="item in companies"
                                    class="select-default"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <label>Contratto con azienda</label>
                        <div class="row">
                            <el-select class="select-default col-12 mb-3" v-model="user.employment_company" filterable
                                       placeholder="..." size="large">
                                <el-option
                                    v-for="item in employment_companies"
                                    class="select-default"
                                    :key="item.key"
                                    :label="item.name"
                                    :value="item.key">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </el-form>

            <div class="my-4">
                <el-button class="mr-2 text-danger" icon="delete" @click.prevent="deleteUser"
                           :disabled="!canDelete">Elimina
                </el-button>
                <el-button type="primary" icon="check" @click.prevent="validateForm" :disabled="disabledUpdated">
                    Aggiorna
                </el-button>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <h4 class="my-4">Modifica password</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <el-input type="input"
                              label="Modifica Password"
                              placeholder="Compila qui se vuoi cambiare la password"
                              name="userPassword"
                              ref="userPassword"
                              v-model="userPassword">
                    </el-input>
                </div>
                <div class="col-6">
                    <el-input type="input"
                              label="Conferma password"
                              placeholder="Compila qui se vuoi cambiare la password"
                              name="confirmPassword"
                              ref="confirmPassword"
                              v-model="confirmPassword">
                    </el-input>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <el-button type="primary" icon="check" @click.prevent="changePassword"
                               v-if="validPasswordCheck">Modifica password
                    </el-button>
                </div>
            </div>

        </div>
    </el-card>
</template>

<script>
import {Switch as LSwitch} from 'src/components/index'
import Swal from "sweetalert2";
import TwoFactorAuthenticationSection from "@/pages/Dashboard/Users/UserProfile/TwoFactorAuthenticationSection";

export default {
    components: {
        TwoFactorAuthenticationSection,
        [Option.name]: Option,
        LSwitch
    },
    props: {
        user: null
    },
    data() {
        return {
            loading: false,
            roles: this.$store.getters.commonElements.roles,
            companies: this.$store.getters.commonElements.companies,
            updateAllowed: true,
            userPassword: '',
            confirmPassword: '',
            modelValidations: {
                userPassword: {
                    required: false,
                    min: 8,
                    max: 25
                },
                confirmPassword: {
                    required: false,
                    confirmed: 'userPassword',
                    min: 8,
                    max: 25
                },
                email: {
                    required: false,
                    email: true
                }
            },
            employment_companies: []
        }
    },
    computed: {
        disabledUpdated: function () {
            if (!this.updateAllowed) return true
            return false
        },
        canDelete: function () {
            return this.$store.getters['users/canDelete'](this.user)
        },
        validPasswordCheck: function () {
            return this.userPassword.length > 0 && (this.userPassword === this.confirmPassword);
        }
    },
    methods: {
        checkRole() {
            if (this.user.role === this.user.role) return true
            if (this.user.role < this.myPermissionLevel && !this.isDeveloper) {
                Swal.fire({
                    title: `Eh no!`,
                    html: 'Non puoi dare permessi di entità superiore rispetto ai tuoi!<br /><br />Imposta il ruolo corretto e riprova!',
                    type: 'warning'
                })
                this.user.role = this.user.role
                return false
            } else if (this.user.role > this.myPermissionLevel && this.user.id === this.myUserId) {
                Swal.fire({
                    title: `Eh no!`,
                    html: 'Non puoi regredire rispetto ai tuoi permessi, capitano!<br /><br />Imposta il ruolo corretto e riprova!',
                    type: 'warning'
                })
                this.user.role = this.user.role
                return false
            }
            return true
        },
        deleteUser() {
            Swal.fire(this.deleteMessage(this.user.name)).then((result) => {
                if (!result.value) return
                this.$store.dispatch('users/delete', this.user).then(() => {
                    Swal.fire('Eliminato!',
                        'L\'utente è stato eliminato.',
                        'success'
                    ).then(() => {
                        this.$router.push('/utenti/lista')
                    })
                }).catch((e) => {
                    this.showLoadingError(e, 'l\'eliminazione dell\'utente', 'update-errorpopup-event')
                })
            })
        },
        loadEmploymentCompanies() {
            this.loading = true
            this.$store.dispatch('users/getEmploymentCompanies').then((response) => {
                this.loading = false
                this.employment_companies = response
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il caricamento delle aziende')
            })
        },
        setProfile() {
            if (!this.checkRole()) return false
            this.loading = true
            var params = {
                name: this.user.name,
                email: this.user.email,
                role: this.user.role,
                admin_access: this.user.admin_access,
                company_id: this.user.company_id,
                employment_company: this.user.employment_company,
                working_hours: this.user.working_hours,
                included_attendance_export: this.user.included_attendance_export
            }

            if (this.userPassword !== '') params = Object.assign(params, {password: this.userPassword})

            params = Object.assign(params, this.$store.getters.apiCommon)
            this.axios.post(this.$config.endpoints.users.base + this.user.id + '/edit', params)
                .then((response) => {
                    this.loading = false
                    if (!response.data.result) {
                        if (!this.isValidToken(response)) return
                        this.loading = false
                        this.showLoadingError(response, 'la verifica del responso dell\'invio al server dei dati aggiornati', 'update-errorpopup-event')
                        return
                    }
                    this.$emit('update-user', this.user)
                    this.userPassword = ''
                    this.showGenericSuccess('Utente')
                })
                .catch(e => {
                    this.loading = false
                    this.showLoadingError(e, 'l\'invio al server dei nuovi dati dell\'utente', 'update-errorpopup-event')
                })
        },
        changePassword() {
            this.loading = true
            let params = {
                user_id: this.user.id,
                password: this.userPassword,
                password_confirmation: this.confirmPassword
            }

            this.$store.dispatch('users/changePassword', params).then((response) => {
                this.$message({
                    message: 'Password modificata con successo.',
                    type: 'success'
                });
            }).catch(e => {
                this.$message({
                    message: 'Errore nella modifica della password',
                    type: 'error'
                });
            }).finally(() => {
                this.loading = false
                this.userPassword = ''
                this.confirmPassword = ''
            })
        },
        validateForm() {
            this.setProfile()
        }
    },
    created: function () {
        this.loadEmploymentCompanies()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style></style>
