<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <div class="navbar-minimize">
                <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
                    <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>
                </button>
            </div>

            <div style="margin-right: 20px;" id="historyBack">
                <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="historyBack">
                    <i :class="'fa fa-arrow-left'"></i>
                </button>
            </div>

            <a class="navbar-brand">{{ this.routeName }}</a>

            <button type="button"
                    class="navbar-toggler navbar-toggler-right"
                    :class="{toggled: $sidebar.showSidebar}"
                    aria-expanded="false"
                    @click="toggleSidebar">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">

                <ul class="navbar-nav">


                    <drop-down position="right">
                        <i slot="title" class="nc-icon nc-bullet-list-67"></i>

                        <a href="#" class="dropdown-item text-danger" v-on:click="logout">
                            <i class="nc-icon nc-button-power"></i> Log out
                        </a>

                    </drop-down>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import DropDown from "@/components/Dropdown";

export default {
    components: {DropDown},
    computed: {
        routeName() {
            const {name} = this.$route
            return this.capitalizeFirstLetter(name)
        }
    },
    data() {
        return {}
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        toggleSidebar() {
            this.$emit('toggleSidebar', this.$sidebar.showSidebar)
        },
        minimizeSidebar() {
            this.$sidebar.toggleMinimize()
        },
        historyBack() {
            this.$router.go(-1)
        }
    }
}

</script>
<style>
@media only screen  and (max-width: 991px) {
    #historyBack {
        display: none;
    }
}

</style>
