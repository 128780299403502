<template>
    <div class="wrapper" :class="{'nav-open': showSidebar}" v-if="validToken" v-loading="loading"
         style="overflow-x: hidden">
        <snow></snow>
        <sidebar style="z-index: 10;"/>

        <div class="main-panel">
            <top-navbar @toggleSidebar="toggleSidebar"/>

            <dashboard-content/>

            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'
import Snow from './snow'
import Sidebar from "@/components/SidebarPlugin/SideBar";

export default {
    data() {
        return {
            validToken: false,
            loading: false,
            currentSearchOrdine: '',
            showSidebar: false
        }
    },
    created: function () {
        this.getCurrentHeartbeat(true) // true = Auto logout se scaduto
            .then(response => {
                if (response.data.heartbeat) {
                    this.validToken = true
                }
            })
            .catch(e => {
            })
    },
    watch: {
    },
    components: {
        Sidebar,
        Snow,
        TopNavbar,
        ContentFooter,
        DashboardContent,
        UserMenu
    },
    methods: {
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
            this.$sidebar.displaySidebar(this.showSidebar)
        }
    }
}

</script>
<style lang="scss">
.vue-notifyjs.notifications {
    .list-move {
        transition: transform 0.3s, opacity 0.4s;
    }

    .list-item {
        display: inline-block;
        margin-right: 10px;

    }

    .list-enter-active {
        transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }

    .list-leave-active {
        transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
        opacity: 0;
        transform: scale(1.1);

    }

    .list-leave-to {
        opacity: 0;
        transform: scale(1.2, 0.7);
    }
}

.cercaOrdineForm .form-group input.form-control {
    width: 180px !important;
    height: 30px !important;
}

.cercaOrdineForm .form-group input::placeholder {
    color: #ccc !important;
}
</style>
