<template>
  <div v-loading="loading">
    <el-form :model="credentials" :rules="rules" ref="loginForm" @submit.prevent.native="validate" label-position="top">
      <el-form-item label="Email" prop="email">
        <el-input v-model="credentials.email"/>
      </el-form-item>
      <el-form-item prop="password" label="Password">
        <el-input type="password" v-model="credentials.password"/>
      </el-form-item>
      <div class="text-center mt-4">
        <el-button type="primary" round v-bind:disabled="submitted" native-type="submit">
          DIAMOCI DENTRO
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      credentials: {
        email: null,
        password: null
      },
      rules: {
        email: [
          {required: true, type: 'email', message: 'Campo obbligatorio', trigger: 'blur'}
        ],
        password: [
          {required: true, message: 'Campo obbligatorio'}
        ]
      },
      submitted: false,
      loading: false
    }
  },
  methods: {
    validate() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          return
        }
      });
    },
    submit() {
      this.submitted = true
      this.loading = true

      this.axios.post(this.$config.endpoints.auth.login,
        {
          api_key: this.$config.apiKey,
          email: this.credentials.email,
          password: this.credentials.password
        }).then(response => {
        let responseData = response.data.data

        if (!responseData.admin_access) {
          this.$store.dispatch('logout', false)
          this.showErrorMessage("Non hai i permessi per accedere a questa risorsa con il tuo acount 🤷‍♂️.")
          return;
        }
        this.$emit('logged', responseData)
      }).catch(e => {
        this.showErrorMessage("Memoria corta? I dati di accesso inseriti non sono corretti.")
      }).finally(() => {
        this.submitted = false
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
