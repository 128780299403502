<template>
  <el-card v-loading="loading">
    <div slot="header">
      <h4 class="title mt-0 mb-4">Export tracciato TeamSystem</h4>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Anagrafiche da esportare: {{export_count}}</h5>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-4">
        <label>Limite dati da esportare<br>*lasciare vuoto per esportare tutti i record</label>
        <el-input type="number"
                  v-model="export_limit" />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <el-button type="primary" @click="download">SCARICA TRACCIATO</el-button>
      </div>
    </div>
  </el-card>
</template>

<script>

import fileDownload from 'js-file-download'

export default {
  name: "ExportTSTrackPage",
  components: {
    fileDownload
  },
  data() {
    return {
      loading: false,
      export_count: 0,
      export_limit: null
    }
  },
  created() {
    this.getExportCount()
  },
  methods: {
    download() {
      this.loading = true
      var params = {};
      if(this.export_limit > 0){
        params.limit = this.export_limit;
      }

      params = Object.assign(params, this.$store.getters.apiCommon)

      this.axios.post(this.$config.endpoints.teamSystem.export, params)
        .then((response) => {
          this.loading = false
          fileDownload(response.data, 'ts_export_' + new Date().getTime() + '.dat')
        })
        .catch(e => {
          this.loading = false
          this.showResponseErrorMessage(e)
        })
    },
    getExportCount(){
      var params = {};
      params = Object.assign(params, this.$store.getters.apiCommon)
      this.axios.post(this.$config.endpoints.teamSystem.count, params)
        .then((response) => {
          this.export_count = response.data.data
        }).catch(e => {
          this.showLoadingError(e)
        })
    }
  }
}
</script>

<style scoped>

</style>
