<template>
  <div class="user">
    <div class="photo">
      <img :src="user.profile_pic ? user.profile_pic : '/static/img/faces/face-0.jpg'" alt="avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" href="#">
           <span>
             {{user.name}}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <router-link class="profile-dropdown" :to="{ path: '/utenti/lista/modifica/' + user.id }">
                  <span class="sidebar-mini">MU</span>
                  <span class="sidebar-normal">Modifica Utente</span>
                </router-link>
              </li>
            </slot>
            <li>
              <a class="profile-dropdown" href="#" v-on:click="logout">
                <span class="sidebar-mini">LO</span>
                <span class="sidebar-normal">LogOut</span>
              </a>
            </li>
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    props: {
      title: {
        type: String,
        default: 'Utente'
      }
    },
    data () {
      return {
        isClosed: true,
        user: {}
      }
    },
    created() {
      this.user = this.$store.getters.user
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      },
      logout () {
        this.$store.dispatch('logout')
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
