<template>
  <div class="row" v-if="canShowView">
    <div class="col-12">
      <el-card title="Aggiungi stato ordine">
        <status-form v-bind:status="status" v-bind:parentStatuses="parentStatuses" v-bind:flows="flows" v-loading="loading" @update="updateForm"></status-form>
      </el-card>
    </div>
  </div>
</template>
<script>
  import StatusForm from './StatusForm.vue'

  export default {
    components: {
      StatusForm
    },
    computed: {},
    watch: {
      '$route.params.id': function () {
        if (this.$route.params.id > 0) this.status.parent_status_id = parseInt(this.$route.params.id)
      }
    },
    data () {
      return {
        componentSettings: {
          myComponentName: 'StatusesAdd',
          minimumPermissionLevel: 100
        },
        loading: false,
        canShowView: false,
        status: {
          flow_order: 10,
          parent_status_id: 0,
          countable: true
        },
        parentStatuses: {},
        flows: {}
      }
    },
    methods: {
      updateParent () {
        this.loading = true
        this.$store.dispatch('orders/statuses/list', {}).then((response) => {
          this.parentStatuses = response.data.order_statuses
          this.updateFlows()
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento della lista stati madre')
        })
      },
      updateFlows () {
        this.loading = true
        this.$store.dispatch('orders/statuses/getFlows', {}).then((response) => {
          this.loading = false
          this.flows = response
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'aggiornamento dell\'elenco del flusso')
        })
      },
      updateData () {
        this.loading = true
        this.updateParent()
      },
      updateForm (pStatus) {
        this.loading = true
        this.$store.dispatch('orders/statuses/add', pStatus).then((response) => {
          this.loading = false
          this.$router.push('/stati/lista/')
        }).catch(e => {
          this.loading = false
          this.showLoadingError(e, 'l\'inserimento di questo stato')
        })
      }
    },
    created: function () {
      if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
      this.canShowView = true

      if (this.$route.params.id > 0) this.status.parent_status_id = parseInt(this.$route.params.id)
      this.updateData()
    },
    destroyed: function () {
      this.$off()
    }
  }
</script>
<style>
  .el-table__expand-column {
    padding-left: 6px !important;
  }
</style>
