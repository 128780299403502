import moment from 'moment'
import Swal from 'sweetalert2'

const napalmPlugin = {
    install(Vue, options) {
        Vue.checkResponse = function () {
        }

        var globalUtils = { // Tutto ciò che viene dichiarato qui è utilizzabile da qualsiasi altro componente, quindi inserire solo ciò che serve realmente
            data: function () {
                return {
                    booleanOptions: [
                        {label: 'No', key: 0},
                        {label: 'Sì', key: 1}
                    ]
                }
            },
            computed: {
                myPermissionLevel: function () {
                    return this.$store.getters.role
                },
                myUserId: function () {
                    return this.$store.getters.userId
                },
                isDeveloper: function () {
                    return this.$store.getters.role <= 0
                }
            },
            methods: {
                parsedLabelText: function (pText, pSpaced = true) {
                    if (pText !== null && pText !== undefined && pText !== '') {
                        if (pSpaced) {
                            return ' (' + pText + ')'
                        }
                        return '(' + pText + ')'
                    }
                    return ''
                },
                async askToggleMessage(pName) {
                    return Swal.fire(this.toggleMessage(pName))
                },
                toggleMessage: function (pName) {
                    return {
                        title: 'Lo vuoi davvero?',
                        html: 'Il valore di <strong>' + pName + '</strong> verrà modificato rispetto al suo valore attuale (se abilitato, verrà disabilitato e viceversa).<br /><br />Puoi premere anche invio sulla tastiera per confermare!',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sì, conferma!',
                        cancelButtonText: 'No, ho sbagliato!',
                        reverseButtons: true
                    }
                },
                async askGenericSuccess(pTitle = 'Fatto!', pMessage = 'L\'operazione è stata completata') {
                    return Swal.fire(pTitle, pMessage, 'success')
                },
                async askGenericConfirm(pTitle = 'Lo vuoi davvero?', pMessage = 'Premendo Sì confermerai questa operazione', pConfirm = 'Sì, conferma!', pCancel = 'No, ho sbagliato!') {
                    return Swal.fire(this.genericConfirm(pTitle, pMessage, pConfirm, pCancel))
                },
                genericConfirm: function (pTitle = 'Lo vuoi davvero?', pMessage = 'Premendo Sì confermerai questa operazione', pConfirm = 'Sì, conferma!', pCancel = 'No, ho sbagliato!') {
                    return {
                        title: pTitle,
                        html: pMessage,
                        showCancelButton: true,
                        confirmButtonText: pConfirm,
                        cancelButtonText: pCancel,
                        reverseButtons: false
                    }
                },
                async askDuplicateMessage(pName) {
                    return Swal.fire(this.duplicateMessage(pName))
                },
                duplicateMessage: function (pName) {
                    return {
                        title: 'Vuoi duplicare ' + pName + '?',
                        html: 'Andrai a duplicare <strong>' + pName + '</strong> in un nuovo elemento!',
                        showCancelButton: true,
                        confirmButtonText: 'Sì, duplica!',
                        cancelButtonText: 'No, ho sbagliato!',
                        reverseButtons: false,
                        backdrop: this.$config.alert.backgrounds.duplicate
                    }
                },
                async askDeleteMessage(pTitle) {
                    return Swal.fire(this.deleteMessage(pTitle));
                },
                deleteMessage: function (pName) {
                    if (pName === '' || !pName) pName = 'Sei sicuro di voler eliminare questo elemento?';
                    return {
                        title: pName,
                        html: "Non potrai tornare indietro!<br />Dovrai ricreare l'elemento da zero.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sì, cancella!',
                        cancelButtonText: 'No, ho sbagliato!',
                        backdrop: this.$config.alert.backgrounds.destroy
                    }
                },
                async showGenericError(pTitle = 'Errore!', pMessage = '') {
                    return Swal.fire({
                        title: pTitle,
                        html: pMessage,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                },
                async showGenericSuccess(pName = 'Elemento', pEvent = 'aggiornato con successo', pTimer = 1000, pTitle = 'Fatto!') {
                    return Swal.fire({
                        title: pTitle,
                        html: pName + ' ' + pEvent + '!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        timer: pTimer
                    })
                },
                async showSessionExpired() {
                    return Swal.fire({
                        title: `Sessione scaduta!`,
                        html: 'Purtroppo la tua sessione non è più valida. Probabilmente hai eseguito il login da un altro dispositivo con lo stesso account, oppure si è verificato un problema tecnico.<br /><br />Prova ad accedere di nuovo!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'OK'
                    })
                },
                getCurrentHeartbeat(pWillLogout = false, pShowPopup = false) {
                    return new Promise((resolve, reject) => {
                        if ((this.$store.getters.sessionToken == null)) {
                            if (pWillLogout) {
                                this.$store.commit('setRequestedPath', this.$route.fullPath)
                                this.$store.dispatch('logout')
                                if (pShowPopup) this.showSessionExpired()
                            }
                            resolve({data: {result: false, heartbeat: false}}) // Simulo risposta da server per evitare doppia implementazione
                            return
                        }
                        this.axios.post(this.$config.endpoints.auth.heartbeat, this.$store.getters.apiCommon)
                            .then(response => {
                                if (pWillLogout) {
                                    if (!response.data.result || !response.data.heartbeat) {
                                        this.$store.commit('setRequestedPath', this.$route.fullPath)
                                        this.$store.dispatch('logout')
                                        if (pShowPopup) this.showSessionExpired()
                                        resolve({data: {result: false, heartbeat: false}})
                                        return
                                    }
                                } else if (response.data.heartbeat && this.$store.getters.lastExpiryDate !== response.data.heartbeat.expiry_at) {
                                    this.$store.commit('shownSessionReminder', false)
                                    this.$store.commit('setLastExpiryDate', response.data.heartbeat.expiry_at)
                                }
                                resolve(response)
                            })
                            .catch(e => {
                                reject(e)
                            })
                    })
                },
                handleImagePreview(file) {
                    if (!file.url) return false
                    Swal.fire({imageUrl: file.url})
                },
                async showLoadingErrorWithoutRetry(pResponseObject, pErrorEvent = '', pSilcenceIfNotFound = false) {
                    return this.showLoadingError(pResponseObject, pErrorEvent, '', false, pSilcenceIfNotFound)
                },
                async showLoadingError(pResponseObject, pErrorEvent = '', pEventName = 'loading-errorpopup-event', pEnableRetry = true, pSilenceIfNotFound = false) {
                    var error = 'Errore di comunicazione con il server'
                    var errorDescription = 'l\'aggiornamento dei dati con il server'
                    if (pErrorEvent !== '' && pErrorEvent !== null) errorDescription = pErrorEvent
                    if (pResponseObject === null || pResponseObject === undefined) pResponseObject = 'Errore sconosciuto'
                    var tipo = typeof pResponseObject
                    if (tipo === 'string') {
                        error = pResponseObject
                    } else if (tipo === 'object') {
                        if (pResponseObject.data === undefined) pResponseObject.data = pResponseObject // Per upload file
                        if (pResponseObject instanceof TypeError) {
                            error = pResponseObject.name + ' - ' + pResponseObject.message
                        } else if (pResponseObject instanceof Error) {
                            error = pResponseObject.name + ' - ' + pResponseObject.message
                        }
                        try {
                            if (pResponseObject.data !== undefined) {
                                if (pResponseObject.data.messages !== undefined && pResponseObject.data.messages.length > 0) {
                                    error = pResponseObject.data.messages[0]
                                    if (typeof error === 'object' || pResponseObject.data.messages.length > 1) error = JSON.stringify(pResponseObject.data.messages, null, 4)
                                } else if (pResponseObject.data.validation_errors !== undefined) {
                                    error = JSON.stringify(pResponseObject.data.validation_errors, null, 4)
                                } else if (pResponseObject.data.exception !== undefined && pResponseObject.data.exception.message !== undefined) {
                                    error = pResponseObject.data.exception.message
                                    if (typeof error === 'object') error = JSON.stringify(pResponseObject.data.exception.message, null, 4)
                                } else if (pResponseObject.data.messages !== undefined) {
                                    error = JSON.stringify(pResponseObject.data.messages, null, 4)
                                }
                            }
                        } catch (err) {
                            error = 'Eccezione: ' + err.name + ' -  ' + err.message
                        }
                    }

                    if (typeof error === 'string') {
                        if (error.search('Session token') !== -1) {
                            this.getCurrentHeartbeat(true, true)
                            return
                        }
                    }
                    if (error.indexOf('non trovato') && pSilenceIfNotFound) return

                    return Swal.fire({
                        title: `Oh oh!`,
                        html: 'Si è verificato un errore durante ' + errorDescription + ': <br /><br /><b>' + error + '</b>',
                        icon: 'error',
                        showCancelButton: pEnableRetry,
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Riprova'
                    }).then((result) => {
                        if (pEventName !== null && pEventName !== '') this.$emit(pEventName, result)
                    })
                },
                async showResponseErrorMessage(error, pTimer = 5000) {

                    let title = "C'è stato un problema!"
                    let message = "Qualcosa è andato storto durante l'operazione. Riprovare."

                    if (error.response !== undefined) {
                        if (error.response.status !== 200) {
                            console.log(error.response)
                            title = "Oh no!"

                            let errorMessage = error.response.data.message;
                            if (error.response.data.error_message !== undefined) {
                                errorMessage = error.response.data.error_message;
                            } else if (error.response.data.data && error.response.data.data.error_message !== undefined) {
                                errorMessage = error.response.data.data.error_message;
                            }

                            let errorDetails = null;

                            if (error.response.data && error.response.data.file) {
                                errorDetails = error.response.data.file + "@" + error.response.data.line;
                            }

                            if (error.response.data.error_details !== undefined) {
                                errorDetails = error.response.data.error_details;
                            }

                            if (errorDetails) {
                                message = "<code>" + errorMessage + "<hr>" + errorDetails + "</code>"
                            } else {
                                message = "<code>" + errorMessage + "</code>"
                            }
                        }
                    } else if (error.data && error.data.validation_errors !== undefined) {
                        title = "Attento ai dati!"
                        message = "Il server ha rilevato un errore nalla compilazione dei dati.<br><hr>Campi non validi:";
                        console.log(error.data.validation_errors)
                        for (const property in error.data.validation_errors) {
                            message += "<br><code>" + property + "</code>"
                        }
                    }

                    return Swal.fire(
                        {
                            title: title,
                            html: message,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            //timer: pTimer
                        })
                },
                async showSuccessMessage(message, pTimer = 1000, pTitle = 'Fatto!') {
                    return Swal.fire(
                        {
                            title: pTitle,
                            html: message,
                            icon: 'success',
                            confirmButtonText: 'OK',
                            timer: pTimer,
                            timerProgressBar: true
                        })
                },
                async showErrorMessage(message, pTimer = 0) {
                    return Swal.fire(
                        {
                            title: "Oh no!",
                            html: message,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            timer: pTimer
                        })
                },
                calculateTableWidth(pColumn, pTableWidth = 1000) { /* Modificare anche nel CSS custom */
                    var pPercent = pColumn
                    if (typeof pColumn === 'object') pPercent = pColumn.percentWidth
                    var risultato = (pTableWidth / 100) * pPercent
                    if (pColumn.first === true) risultato-- // Risolve bug su Firefox eliminando 1px dalla prima colonna
                    return risultato
                },
                checkIfPermissionLevelIsAtLeast(pMinimumPermissionLevel, pRedirectIfGreater = true) {
                    if (this.$store.getters.role > pMinimumPermissionLevel || this.$store.getters.role === undefined) {
                        if (pRedirectIfGreater) {
                            this.$store.commit('redirToDashboard')
                        }
                        return false
                    }
                    return true
                },
                convertOrderDirection(pOrder) {
                    if (pOrder === 'ascending') return 'asc'
                    return 'desc'
                },
                dateToItalian(pUsaDate, pShowPassed = false, pCustomFormat = 'DD/MM/YYYY HH:mm:ss') {
                    if (!pUsaDate) return ''
                    let originalFormat = 'YYYY-MM-DD HH:mm:ss';
                    if (pUsaDate.length == 10 && pUsaDate.includes('-')) {
                        originalFormat = 'YYYY-MM-DD';
                    } else if (pUsaDate.length == 10 && pUsaDate.includes('/')) {
                        originalFormat = 'DD/MM/YYYY';
                    }
                    var italianDate = moment(String(pUsaDate), originalFormat).format(pCustomFormat)
                    if (pShowPassed) {
                        return italianDate + ' (' + this.timePassedFromDate(pUsaDate) + ')'
                    } else {
                        return italianDate
                    }
                },
                timePassedFromDate(pUsaDate) {
                    if (!pUsaDate) return ''
                    moment.locale('it')
                    let originalFormat = 'YYYY-MM-DD HH:mm:ss';
                    if (pUsaDate.length == 10 && pUsaDate.includes('-')) {
                        originalFormat = 'YYYY-MM-DD';
                    } else if (pUsaDate.length == 10 && pUsaDate.includes('/')) {
                        originalFormat = 'DD/MM/YYYY';
                    }
                    return moment(String(pUsaDate), originalFormat).fromNow()
                },
                daysBetweenDates(pInitial, pFinal) {
                    if (!pInitial || !pFinal) return -1
                    var date1 = moment(pInitial)
                    var date2 = moment(pFinal)
                    var diff = date2.diff(date1, 'days')
                    return diff
                },
                isValidToken(payload, pShouldLogout = true) {
                    if (payload.data.result) return true
                    if (!payload.data.messages) return true
                    if (payload.data.messages.length < 1) return true
                    Object.keys(payload.data.messages).map((v, i) => {
                        if (v === this.$config.messageCodes.invalidSessionToken) {
                            if (pShouldLogout) this.$store.dispatch('logout')
                            return false
                        }
                    })
                    return true
                },
                findColumnSort(pProp) {
                    for (let index = 0; index < this.tableColumns.length; ++index) {
                        if (this.tableColumns[index].prop === pProp.prop) {
                            if (this.tableColumns[index].sortby) {
                                return this.tableColumns[index].sortby
                            } else {
                                return pProp.prop
                            }
                        }
                    }
                },
                getStatusInfoById(pStatusId) {
                    return this.$store.getters['orders/statuses/getStatusInfoById'](pStatusId)
                },
                getStatusInternalNameById(pStatusId) {
                    return this.$store.getters['orders/statuses/getStatusInfoById'](pStatusId).internal_name
                },
                getStatusNameById(pStatusId) {
                    return this.$store.getters['orders/statuses/getStatusInfoById'](pStatusId).name
                },
                getTypeInfoById(pTypeId) {
                    return this.$store.getters['orders/getTypeInfoById'](pTypeId)
                },
                getTypeNameById(pTypeId) {
                    return this.$store.getters['orders/getTypeInfoById'](pTypeId).name
                },
                colorNumbers(pValue) {
                    if (pValue > 0) {
                        return '<strong class="a-link">' + pValue + '</strong>'
                    }
                    return pValue
                },
                getTotalOrdersInStatusByType(pStatus, pType, pPaid, pChildCount = true) {
                    pType = [parseInt(pType)]
                    if (pChildCount && pStatus.child_count) {
                        return this.getTotalOrdersInStatusWithChildByType(pStatus, pType, pPaid)
                    }
                    if (pType[0] === 0) pType = [null, 'all']
                    for (let index = 0; index < pStatus.orders_count.length; ++index) {
                        for (let internal = 0; internal < pType.length; ++internal) {
                            if (pStatus.orders_count[index].type === pType[internal]) {
                                if (pPaid === 1) {
                                    return pStatus.orders_count[index].paid
                                } else if (pPaid === 0) {
                                    return pStatus.orders_count[index].unpaid
                                } else {
                                    return pStatus.orders_count[index].count
                                }
                            }
                        }
                    }
                    return 0
                },
                getTotalOrdersInStatusWithChildByType(pStatus, pType, pPaid) {
                    pType = parseInt(pType[0])
                    for (let typeId in pStatus.child_count) {
                        let count
                        if (pPaid === 1) {
                            count = pStatus.child_count[typeId].paid
                        } else if (pPaid === 0) {
                            count = pStatus.child_count[typeId].unpaid
                        } else {
                            count = pStatus.child_count[typeId].count
                        }
                        if (pType === 0 && typeId === 'all') return count
                        if (pType === parseInt(typeId)) return count
                    }
                    return 0
                },
                isSnowing() {
                    return moment().month() === 11 && moment().date() <= 26
                }
            }
        }

        Vue.mixin(globalUtils)
    }
}
export default napalmPlugin
