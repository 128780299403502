<template>
    <div class="row">
        <div class="col-12 col-lg-8 col-md-7">
            <edit-profile-form v-bind:user="this.user" v-loading="loading" @update-user="onUserUpdated"/>
        </div>
        <div class="col-12 col-lg-4 col-md-5">
            <user-card v-bind:user="this.user" v-loading="loading" @update-user="onUserUpdated"/>
        </div>
    </div>
</template>
<script>
import EditProfileForm from './UserProfile/EditProfileForm.vue'
import UserCard from './UserProfile/UserCard.vue'

export default {
    components: {
        EditProfileForm,
        UserCard
    },
    data() {
        return {
            user: false,
            loading: true,
            minimumPermissionLevel: 100
        }
    },
    watch: {
        '$route.params.id': function (id) {
            if (!id) {
                return
            }
            this.updateData()
        }
    },
    methods: {
        onUserUpdated: function (newUser) {
            this.user = JSON.parse(JSON.stringify(newUser)) // alternativa JSON.parse(JSON.stringify(newUser)) o Object.assign({}, newUser) -- metodo poco carino ma senza librerie esterne è l'unico
            if (this.user.id === this.myUserId) {
                this.$store.commit('saveUser', this.user)
            }
        },
        updateData(params) {
            this.loading = true
            if (!params) params = {}
            params = Object.assign(params, this.$store.getters.apiCommon)

            this.axios.post(this.$config.endpoints.users.base + this.$route.params.id, params)
                .then((response) => {
                    this.loading = false
                    if (!response.data.result) {
                        if (!this.isValidToken(response)) return
                        this.loading = false
                        this.showLoadingError(response)
                        return
                    }
                    if (!this.checkIfPermissionLevelIsAtLeast(this.minimumPermissionLevel, false)) {
                        if (response.data.data.id !== this.myUserId) {
                            this.$router.push('/utenti/lista')
                            return
                        }
                    }
                    if (this.myPermissionLevel > response.data.data.role && !this.isDeveloper) {
                        this.$router.push('/utenti/lista')
                        return
                    }
                    this.user = response.data.data
                })
                .catch(e => {
                    this.showLoadingError(e)
                })
        }
    },
    created: function () {
        this.updateData()
    },
    mounted: function () {
    },
    destroyed: function () {
        this.$off()
    }
}

</script>
<style>

</style>
