<template>
    <el-card class="card-user" style="position: relative">
        <el-image class="user-cover" src="/static/img/cover.jpg"/>
        <div class="d-flex flex-column mt-5">
            <el-image style="height: 150px; width: 150px; border: 3px solid #dee2e6 !important" :src="urlAvatar" fit="fill"
                      class="mx-auto rounded-circle border border-gray"/>
            <div class="mx-auto mt-2">
                <el-upload
                    v-model:file-list="files"
                    :action="postUrl"
                    :on-change="handleChange"
                    :data="requestData"
                    name="profile_pic">
                    <el-button @click="urlAvatar = null">Cambia immagine</el-button>
                </el-upload>
            </div>
        </div>
        <h4 class="mt-4 text-center">{{ mutableUser.name }}
            <br>
            <small>{{ mutableUser.email }}</small>
        </h4>
    </el-card>
</template>
<script>
import FileUpload from 'vue-upload-component'
import {Progress as LProgress} from 'src/components/index'
import Swal from "sweetalert2";

export default {
    components: {
        FileUpload,
        LProgress
    },
    props: ['user'],
    data() {
        return {
            files: [],
            postUrl: null,
            progress: 0,
            requestData: this.$store.getters.apiCommon,
            mutableUser: this.user,
            cssAvatar: '',
            urlAvatar: ''
        }
    },
    watch: {
        user: function (newUser) {
            if (newUser.id) {
                this.postUrl = this.$config.endpoints.users.base + newUser.id + '/profile-pic/edit'
            }
            this.mutableUser = JSON.parse(JSON.stringify(newUser))
            this.urlAvatar = this.mutableUser.profile_pic ? this.mutableUser.profile_pic : '/static/img/avatar.jpg'
            this.cssAvatar = 'background-image: url(\'' + this.urlAvatar + '\'); height: 124px; width: 124px; background-size: cover; background-position: top center; background-repeat: no-repeat; margin: 0 auto 20px auto;'
            // this.files.push({name: 'profile_pic.jpg', url: this.urlAvatar})
        }
    },
    created: function () {
    },
    methods: {
        handleChange(uploadFile, uploadFiles) {
            console.log(uploadFile)
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // Add file
            }

            if (newFile && oldFile) {
                // Update file

                // Start upload
                if (newFile.active !== oldFile.active) {
                    // min size
                    if (newFile.size >= 0 && newFile.size < 5 * 1024) {
                        newFile = this.$refs.upload.update(newFile, {error: 'File troppo piccolo'})
                    }

                    // max size
                    if (newFile.size >= 0 && newFile.size > 1024 * 1024) {
                        newFile = this.$refs.upload.update(newFile, {error: 'File troppo grande, deve essere massimo di 1MB'})
                    }
                }

                // Upload progress
                if (newFile.progress !== oldFile.progress) {
                    this.progress = parseFloat(newFile.progress)
                }

                // Upload error
                if (newFile.error !== oldFile.error) {
                    Swal.fire(
                        'Ops...',
                        'Qualcosa è andato storto, l\'avatar non è stato aggiornato. Errore: ' + newFile.error,
                        'error'
                    )
                }

                // Uploaded successfully
                if (newFile.success !== oldFile.success) {
                    if (newFile.response.result) {
                        Swal.fire(
                            'Olè!',
                            'L\'avatar è stato aggiornato.',
                            'success'
                        )
                        this.mutableUser.profile_pic = newFile.response.profile_pic
                        this.$emit('update-user', this.mutableUser)
                    } else {
                        Swal.fire(
                            'Ops...',
                            'Qualcosa è andato storto, l\'avatar non è stato aggiornato a causa di un errore sul server.',
                            'error'
                        )
                    }
                }
            }

            if (!newFile && oldFile) {
                // Remove file
            }

            // Automatic upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        }
    }
}
</script>

<style>
.user-cover {
    /*background-image: url("/static/img/cover.jpg");*/
    /*background-repeat: no-repeat, repeat;*/
    /*background-position: top;*/
    /*background-size: contain;*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 150px;
}
</style>
