import Sidebar from './SideBar.vue'
import SidebarItem from './SidebarItem.vue'

const SidebarStore = {
    showSidebar: false,
    sidebarLinks: [
        {
            name: 'Ordini', icon: 'nc-icon nc-single-copy-04', minimumPermissionLevel: 200, subLinks: [
                {name: 'Gestione', path: '/ordini/stati', class: 'fa fa-bars'},
                {name: 'Visualizzazione', path: '/ordini/lista', class: 'fa fa-bars'},
                {name: 'Crea ordine', path: '/ordini/aggiungi', class: 'fa fa-plus'},
                {name: 'TS - Esporta Tracciato', path: '/ordini/esporta-tracciato-ts', class: 'fa fa-plus', minimumPermissionLevel: 100},
                {name: 'Carica Giacenza OF', path: '/ordini/giacenza-openfiber-load', class: 'fa fa-plus', minimumPermissionLevel: 100},
                {name: 'Esportazioni', path: '/ordini/esportazioni', class: 'fa fa-plus', minimumPermissionLevel: 100},
                {name: 'Migrazioni in entrata', path: '/ordini/log-risposte', class: 'fa fa-plus', minimumPermissionLevel: 100},
                {name: 'Esportazioni', path: '/ordini/esportazioni', class: 'fa fa-plus', minimumPermissionLevel: 100}
            ]
        },
        {
            name: 'Stati ordine', icon: 'nc-icon nc-bullet-list-67', minimumPermissionLevel: 100, subLinks: [
                {name: 'Mostra', path: '/stati/lista', class: 'fa fa-bars'},
                {name: 'Aggiungi', path: '/stati/aggiungi', class: 'fa fa-plus'}
            ]
        },
        {
            name: 'Offerte', icon: 'nc-icon nc-app', minimumPermissionLevel: 100, subLinks: [
                {name: 'Mostra', path: '/offerte/lista', class: 'fa fa-bars'},
                {name: 'Aggiungi', path: '/offerte/aggiungi', class: 'fa fa-plus'},
                {name: 'Opzioni', path: '/offerte/opzioni/lista', class: 'fa fa-bars'}
            ]
        },
        {
            name: 'Statistiche', icon: 'nc-icon nc-chart-bar-32', minimumPermissionLevel: 200, subLinks: [
                {name: 'Ordini', path: '/statistiche/orders', class: 'sfa fa-line-chart'},
                {name: 'Attivazioni', path: '/statistiche/activations', class: 'fa fa-area-chart'}
            ]
        },
        {
            name: 'Clienti attivi', icon: 'nc-icon nc-atom', minimumPermissionLevel: 100, subLinks: [
                {name: 'Guasti', path: '/clienti-attivi/guasti', class: 'fa fa-bars'},
                {name: 'Migrazioni in uscita', path: '/clienti-attivi/migrazioni-donating', class: 'fa fa-bars'},
            ]
        },
        {
            name: 'Partner', icon: 'nc-icon nc-satisfied', minimumPermissionLevel: 100, subLinks: [
                {name: 'Mostra', path: '/partner/lista', class: 'fa fa-bars'},
                {name: 'Aggiungi', path: '/partner/aggiungi', class: 'fa fa-plus'}
            ]
        },
        {
            name: 'Utenti', icon: 'nc-icon nc-single-02', minimumPermissionLevel: 100, subLinks: [
                {name: 'Mostra', path: '/utenti/lista', class: 'fa fa-bars'},
                {name: 'Aggiungi', path: '/utenti/aggiungi', class: 'fa fa-plus'}
            ]
        },
        {name: 'NP', icon: 'nc-icon nc-notes', path: '/np/lista', minimumPermissionLevel: 100},
        {
            name: 'Notifiche', icon: 'nc-icon nc-email-85', minimumPermissionLevel: 100, subLinks: [
                {name: 'Email', path: '/notifications/email/lista', class: 'fa fa-bars'},
                {name: 'SMS', path: '/notifications/sms/lista', class: 'fa fa-bars'}
            ]
        },
        {
            name: 'Impostazioni', icon: 'nc-icon nc-settings-90', minimumPermissionLevel: 100, subLinks: [
                {name: 'Lista', path: '/impostazioni/lista', class: 'fa fa-bars'},
                // {name: 'Flusso', path: '/impostazioni/flusso', class: 'fa fa-bars'},
                {name: 'Manutenzione', path: '/impostazioni/manutenzione', class: 'fa fa-bars'}
            ]
        },    ],
    isMinimized: false,
    displaySidebar(value) {
        this.showSidebar = value
    },
    toggleMinimize() {
        document.body.classList.toggle('sidebar-mini')
        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(() => {
            window.dispatchEvent(new Event('resize'))
        }, 180)

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(() => {
            clearInterval(simulateWindowResize)
        }, 1000)

        this.isMinimized = !this.isMinimized
    }
}

const SidebarPlugin = {

    install(app, options) {
        if (options && options.sidebarLinks) {
            SidebarStore.sidebarLinks = options.sidebarLinks
        }
        app.mixin({
            data() {
                return {
                    sidebarStore: SidebarStore
                }
            }
        })

        app.config.globalProperties.$sidebar = SidebarStore
        app.component('side-bar', Sidebar)
    }
}

export default SidebarPlugin
