const GlobalInfos = {
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
    apiVersion: process.env.VUE_APP_API_VERSION
}

const tinyMCEConfig = {
    plugins: ['paste', 'link'],
    skin_url: '/static/tinymce/skins/lightgray',
    skin: 'lightgray',
    language_url: '/static/tinymce/langs/it.js'
}

const GlobalConfig = {
    homepage: '/ordini/stati',
    profilePage: '/utenti/lista/modifica/',
    tinyMCEConfig: tinyMCEConfig,
    globalInfos: GlobalInfos,
    defaultAPITimeout: 45000,
    apiKey: process.env.VUE_APP_API_KEY,
    endpoints: {
        activationAddress: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/activation-addresses/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/activation-addresses/add',
        },
        auth: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/',
            login: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/login',
            heartbeat: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/heartbeat',
            two_fa_get_code: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/2fa/get-code',
            two_fa_enable_code: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/2fa/enable',
            two_fa_verify_code: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/auth/2fa/verify',
        },
        colors: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/colors/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/colors/add'
        },
        otherupdates: {
            openfiber: {
                loadgiacenza: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/otherupdates/openfiber/load-giacenza'
            }
        },
        companies: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/companies/'
        },
        compass: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/compass/logs/'
        },
        customers: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/customers/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/customers/add',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/customers/types'
        },
        emailSend: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mail/',
            send: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mail/send'
        },
        inbound: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/inbound/'
        },
        logs: {
            laravel: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/logs/laravel',
            scheduler: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/logs/scheduler',
            clearGeneralLog: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/logs/laravel/clear',
            clearSchedulerLog: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/logs/scheduler/clear'
        },
        medias: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/medias/'
        },
        mnp: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/',
            statuses: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/statuses',
            statuses_count: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/statuses/count'
        },
        notifications: {
            sms: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/sms/templates/',
                add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/sms/templates/add'
            },
            emails: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/email/templates/',
                list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/email/templates/list',
                list_paginated: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/email/templates/list-paginated',
                add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/email/templates/add',
                types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/email/templates/types'
            },
            variables: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/notifications/template-variables'
        },
        offerOption: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offer-options/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offer-options/add'
        },
        offers: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/',
            list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/list',
            list_paginated: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/list-paginated',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/add',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/types',
            export: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/export',
            userTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/user-types',
            shippingTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/shipping-types',
            integratedOffers: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/integrated-offers',
            integratedOffersTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/offers/integrated-offers/types'
        },
        operators: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/operators/'
        },
        orderNotes: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-notes/'
        },
        orderProducts: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-products/',
            operationTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-products/operation-types'
        },
        orderStatuses: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-statuses/',
            list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-statuses/list',
            listStructured: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/order-statuses/list-structured',
        },
        infratel: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/infratel/',
            getFasceVelocitaAgcom: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/infratel/get-fasce-velocita-agcom',
            activateVoucherImprese: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/infratel/activate-voucher-imprese',
        },
        orders: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/types',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/add',
            addEmpty: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/add-empty',
            activeUsers: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/active-users',
            statuses: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/',
                add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/add',
                flows: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/flows',
                notifications: {
                    base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/notifications/',
                    add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/notifications/add',
                    types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/statuses/notifications/types'
                }
            },
            wholesale: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/wholesale/',
                openfiber: {
                    exportCensimenti: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/orders/wholesale/openfiber/export-censimenti'
                }
            },
            mnp: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/',
                add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/add',
                statuses: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/mnp/statuses'
            }
        },
        paymentMethods: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/paymentmethods/',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/paymentmethods/types',
            cardCircuits: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/paymentmethods/card-circuits'
        },
        peoplesoft: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/peoplesoft/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/peoplesoft/add'
        },
        preorderForms: {
            templatePaths: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/preorder-forms/template-paths'
        },
        productAlerts: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/product-alerts/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/product-alerts/add',
            statuses: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/product-alerts/statuses',
            customertypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/product-alerts/customer-types',
            sendNotification: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/product-alerts/send-notification'
        },
        products: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/products/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/products/add',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/products/types',
            brands: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/products/brands',
            categories: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/products/categories'
        },
        promoCodes: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/',
            attach: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/attach',
            getPromos: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/get-promos',
            addPromoType: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/add-promo-type',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/types/',
            resetCode: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/reset-code',
            burnCode: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shop-codes/burn-code'
        },
        rates: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/rates/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/rates/add',
            types: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/rates/types',
            userTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/rates/user-types',
            billingPeriodTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/rates/billing-period-types'
        },
        recharges: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/recharges/',
            getCount: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/recharges/count',
            getAvailableRecharges: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/recharges/count-available'
        },
        receipts: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/receipts/',
            exportCSV: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/receipts/export/csv',
            exportPDF: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/receipts/export/pdf'
        },
        settings: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/settings/',
            edit: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/settings/edit'
        },
        shippings: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/',
            province: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/helpers/province',
            couriers: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/couriers',
            pickPoints: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/pick-points/',
                nearest: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/pick-points/nearest',
                indabox: {
                    notify: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/pick-points/indabox/notify',
                    check: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shippings/pick-points/indabox/check'
                }
            }
        },
        shippingLogs: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shipping-logs/',
            carrierTypes: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shipping-logs/carrier-types',
            shippingStatuses: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/shipping-logs/shipping-statuses'
        },
        statistics: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/statistics/',
            orders: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/statistics/orders',
            activations: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/statistics/activations',
                export: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/statistics/activations/export'
            }
        },
        terms: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/upload/terms/list',
            delete: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/upload/terms/delete'
        },
        utility: {
            timePanel: {
                attendanceLogs: {
                    register: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/time-panel/attendance-logs/register',
                    export: {
                        pdf: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/time-panel/attendance-logs/export/pdf',
                        csv: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/time-panel/attendance-logs/export/csv'
                    }
                }
            },
            usefulLinks: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/useful-links/',
                add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/useful-links/add'
            },
            cfLookUp: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/utility/cf-lookup',
            },
            checkUserIdentifier: {
                base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/dimensione-crm/check-radius-account',
            },
        },
        upload: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/upload/',
            optimomergesign: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/upload/optimo/merge-and-sign',
            terms: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/upload/terms'
        },
        users: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/users/',
            roles: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/users/roles',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/users/add'
        },
        warehouse: {
            genialstore: {
                restore: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/warehouse/genialstore/restore'
            }
        },
        emailCampaigns: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/email-campaigns/',
            add: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/email-campaigns/add'
        },
        teamSystem: {
            export: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/team-system/export-track',
            count: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/team-system/export-count'
        },
        olo: {
            paginatedList: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/list-paginated',
            getOloCodeDonating: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/get-donating-olo-codes',
            previewFile: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/5/send-xml',
            downloadMigrationFile: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/create-xml',
            sentMigrationAutomatically: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/send-xml-migration',
            getOloCow: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/get-recipients-original-cow'
        },
        partner: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/partners/',
            create: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/partners/create',
            list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/partners/list',
            paginatedList: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/partners/paginated-list'
        },
        assurance: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/assurance/',
            create: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/assurance/create',
            list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/assurance/list',
            paginatedList: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/assurance/paginated-list'
        },
        migration_donating: {
            base: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/migration-donating/',
            create: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/migration-donating/create',
            list: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/migration-donating/list',
            paginatedList: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/active-customers/migration-donating/paginated-list',
            accept: GlobalInfos.apiBaseUrl + GlobalInfos.apiVersion + '/olo2olo/migration/9/accept'
        }
    },
    alert: {
        backgrounds: {
            cat: `rgba(0,0,123,0.4)
                url("/static/img/gif/nyan-cat.gif")
                center left
                no-repeat`,
            destroy: `rgba(251, 64, 64, 0.76)
                url("/static/img/gif/flames.gif")
                center left
                no-repeat`,
            error: `rgba(0,0,123,0.2)
                url("/static/img/gif/omg-cat.gif")
                center left
                no-repeat`,
            duplicate: `rgba(0,0,123,0.2)
                url("/static/img/gif/nyan-cat.gif")
                center left
                no-repeat`
        }
    },
    messageCodes: {
        invalidSessionToken: '10'
    }
}

export default GlobalConfig
