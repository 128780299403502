<template>
    <div class="row" v-if="canShowView">
        <div class="col-12">
            <el-card title="Modifica stato ordine">
                <div slot="header">
                    <h4 class="title mt-0">Modifica di <strong>{{ status.internal_name }}</strong></h4>
                    <div class="row">
                        <div class="col-3">
                            <router-link :to="{path:'/stati/lista'}">
                                <button class="btn btn-default btn-outline col-12">
                <span class="btn-label">
                   <i class="fa fa-list"></i> Lista stati
                </span>
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <status-form v-bind:status="status" v-bind:parentStatuses="parentStatuses" v-bind:flows="flows"
                             v-loading="loading" @update="updateForm" @delete="deleteStatus"></status-form>

                <div>
                    <h4>Notifiche che vengono inviate per questo stato</h4>
                    <p v-if="matchedNotifications.length < 1">Non ci sono notifiche per questo stato</p>
                    <div>
                        <ul>
                            <li v-for="(item) in matchedNotifications">
                                <label v-if="item.notification_type == 1">Email: <span v-if="!item.template">Notifica sconosciuta</span>
                                    <router-link v-if="item.template"
                                                 :to="{path:'/notifications/email/lista/modifica/' + item.template.id}">
                                        {{ item.template.name }}
                                    </router-link>
                                    per gli ordini <strong
                                        v-if="item.order_type_details">{{
                                            item.order_type_details.name
                                        }}</strong><strong
                                        v-if="!item.order_type_details">di qualsiasi tipologia</strong> <a
                                        data-tooltip="'Elimina notifica'" class="btn-danger btn-simple btn-link"
                                        @click="deleteNotification(item)"><i class="fa fa-times"></i></a></label>
                                <label v-if="item.notification_type == 2">SMS: <span v-if="!item.template">Notifica sconosciuta</span>
                                    <router-link v-if="item.template"
                                                 :to="{path:'/notifications/sms/lista/modifica/' + item.template.id}">
                                        {{ item.template.name }}
                                    </router-link>
                                    per gli ordini <strong
                                        v-if="item.order_type_details">{{
                                            item.order_type_details.name
                                        }}</strong><strong
                                        v-if="!item.order_type_details">di qualsiasi tipologia</strong> <a
                                        data-tooltip="'Elimina notifica'" class="btn-danger btn-simple btn-link"
                                        @click="deleteNotification(item)"><i class="fa fa-times"></i></a></label>
                            </li>
                        </ul>
                    </div>
                </div>

            </el-card>
        </div>
    </div>
</template>
<script>
import {Pagination as LPagination, Breadcrumb, BreadcrumbItem} from 'src/components/index'
import StatusForm from './StatusForm.vue'
import Swal from "sweetalert2";

export default {
    components: {
        LPagination,
        Breadcrumb,
        BreadcrumbItem,
        StatusForm
    },
    computed: {},
    watch: {
        '$route.params.id': function (val) {
            if (!val) {
                return
            }
            this.updateData()
            this.loadNotifications()
        }
    },
    data() {
        return {
            componentSettings: {
                myComponentName: 'StatusesEdit',
                minimumPermissionLevel: 100
            },
            loading: false,
            canShowView: false,
            status: {},
            parentStatuses: {},
            flows: {},
            matchedNotifications: []
        }
    },
    methods: {
        updateData() {
            this.loading = true
            this.$store.dispatch('orders/statuses/getById', this.$route.params.id).then((response) => {
                this.status = response.data.order_status
                if (!this.status.flow_order) {
                    this.status.flow_order = 0
                }
                this.updateParent()
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'il recupero dei dati di questo stato')
            })
        },
        updateParent() {
            this.loading = true
            this.$store.dispatch('orders/statuses/list', {}).then((response) => {
                this.parentStatuses = response.data.order_statuses
                this.updateFlows()
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiornamento della lista stati madre')
            })
        },
        updateFlows() {
            this.loading = true
            this.$store.dispatch('orders/statuses/getFlows', {}).then((response) => {
                this.loading = false
                this.flows = response
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiornamento dell\'elenco del flusso')
            })
        },
        updateForm(pStatus) {
            this.loading = true
            this.$store.dispatch('orders/statuses/edit', pStatus).then((response) => {
                this.loading = false
                this.status = response.data.order_status
                this.showGenericSuccess('Stato')
            }).catch(e => {
                this.loading = false
                this.showLoadingError(e, 'l\'aggiornamento dei dati di questo stato')
            })
        },
        deleteStatus(pStatus) {
            this.askDeleteMessage('questo stato').then((result) => {
                if (!result.value) return
                this.$store.dispatch('orders/statuses/delete', pStatus).then(() => {
                    Swal.fire('Eliminato!',
                        'Stato eliminato!',
                        'success'
                    ).then(() => {
                        this.$router.push('/stati/lista')
                    })
                }).catch((e) => {
                    this.showLoadingError(e, 'l\'eliminazione dello stato', '', false)
                })
            })
        },
        deleteNotification(pNotification) {
            this.askDeleteMessage('questa notifica').then((result) => {
                if (!result.value) return
                this.$store.dispatch('orders/statuses/notifications/delete', pNotification).then(() => {
                    this.loadNotifications()
                    this.showGenericSuccess('Olè!', 'Notifica eliminata con successo.')
                }).catch((e) => {
                    this.showLoadingErrorWithoutRetry(e, 'l\'eliminazione della notifica')
                })
            })
        },
        loadNotifications() {
            this.$store.dispatch('orders/statuses/notifications/list', {
                status_ids: [parseInt(this.$route.params.id)],
                company_id: 1,
                limit: -1,
                template: true
            }).then((response) => {
                this.matchedNotifications = response.data.notifications
            }).catch(e => {
                this.showLoadingErrorWithoutRetry(e, 'il caricamento delle notifiche connesse')
            })
        }
    },
    created: function () {
        if (!this.checkIfPermissionLevelIsAtLeast(this.componentSettings.minimumPermissionLevel, true)) return
        this.canShowView = true
        this.updateData()
        this.loadNotifications()
    },
    destroyed: function () {
        this.$off()
    }
}
</script>
<style></style>
