// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from './components/SidebarPlugin'

// asset imports
import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/light-bootstrap-dashboard.scss'
import './assets/sass/napalm.scss'
import './assets/css/demo.css'

// library auto imports
import 'es6-promise/auto'

export default {
  async install (app, options) {
    app.use(SideBar)
  }
}
